<template>
  <main>
    <div class="container">
      <div class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md py-5">
          <!-- <div class="row" v-if="confirmePresence">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-primary">Félicitation à vous!</h4>
                <h6 class="text-muted mb-4">Merci pour avoir confirmé votre présence:</h6>
              </div>
            </div>
          </div> -->
          <div class="row" >
            <div class="col-sm-12">
                <h4 class="text-primary text-">{{ confirmePresence }}</h4>
                <h6 class="text-muted mb-4 text-">Veuillez bien définir les identifiants de connexion à votre compte</h6>
              <div class="text- mb-4">
                <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
                  <form ref="form" method="post" @submit.prevent="handleSubmit(onSubmit)" accept-charset="utf-8">
                    <div >Pouvons-nous savoir les raisons de votre absence?</div>
                    <ValidationProvider rules="" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <textarea rows="5" style="min-height: 100px" maxlength="150" v-model="confirmation.motifAbsence" type="text" class="form-control" name="emmotifAbsenceail" id="motifAbsence" placeholder="Raison absence" />
                        <label for="motifAbsence">Motif d'absence</label>
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      </div>
                    </ValidationProvider>
                      <div class="form-floating mb-3">
                        <p-calendar v-model="confirmation.dateDisponibilite" name="dateDisponibilite" id="dateDisponibilite" placeholder="Votre disponibilite" />
                        <label for="dateDisponibilite">Motif d'absence</label>
                      </div>
                    <div class="d-grid gap-2 my-4">
                      <button type="submit" class="btn btn-primary btn-lg">Connexion</button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import { authMixin } from '../../mixins/auth-mixin'

export default {
  props: [ 'participationId' ],
  mixins: [authMixin],
  data(){
    return{
      tokenInfo: null,
      error: null,
      confirmation: {
        confirmePresence: null,
        confirmationToken: null,
        motifAbsence: null,
        dateDisponibilite: null,
      },
    }
  },
  created () {
    console.log(this.$route.query.confirmationToken)
  },
  computed: {
    confirmePresence () {
      return this.$route.query.confirmePresence != true
    }
  },  
  methods: {
    ...mapActions({
      confirmerParticipation: 'participation/confirmerParticipation',
    }),
    async onSubmit () {
      this.loading = true
      this.confirmerParticipation({
        ...this.confirmation,
        confirmePresence: this.$route.query.confirmePresence,
        confirmationToken: this.$route.query.confirmationToken,
        participationId: this.participationId,
      }).then(() => {
        this.$router.push({ name: 'espace.de.home' })
      }).catch(err => this.error = err.response.data)
    }
  },
}
</script>